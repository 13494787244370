body {
  margin: 0;
  color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1{
  margin-top: 0;
}

p{
  margin: 0;
}

.header{
  position: absolute;
  top: 15px;
  left: 15px;
  width: 90%;
  z-index: 999;

  .status{
    top: 15px;
    left: 10px;
    position: relative;
  }
}

.footer{
  position: absolute;
  left: 15px;
  bottom: 15px;
  width: 90%;
  z-index: 999;
}